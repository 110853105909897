<template>
  <carousel-3d :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
    <!-- ocean -->
    <slide :index="0" id="back_slide">
    <div id="link">
          <v-btn
            elevation="2"
            block
            small
            dark
            color="rgba(255, 198, 7, 0.8)"
            class="mx-auto"
            href="/examples/blend4web/ocean.html"
            target="_blank"
            >
            <p>view 4D Webgl</p>
            <v-icon class="ml-auto">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-btn>
      </div>
      <div id="youtube">
        <div>
          <LazyYoutubeVideo src="https://www.youtube.com/embed/_OjKXe6Y9zQ" />
        </div>
      </div>
    </slide>
    <!-- Mark -->
    <slide :index="1" id="back_slide">
    <div id="link">
      <v-btn
        elevation="2"
        block
        small
        dark
        color="rgba(255, 198, 7, 0.8)"
        class="mx-auto"
        href="https://zipgorelok.ru/"
        target="_blank"
        >
      <p>view 4D Webgl</p>
        <v-icon class="ml-auto">
          mdi-arrow-right-drop-circle-outline
        </v-icon>
      </v-btn>
      </div>
      <div id="youtube">
        <div>
          <LazyYoutubeVideo src="https://www.youtube.com/embed/QvpxAXiq9yg" />
        </div>
      </div>
    </slide>
<!-- flame -->
    <slide :index="2" id="back_slide">
      <div id="link">
            <v-btn
            elevation="2"
            block
            small
            dark
            color="rgba(255, 198, 7, 0.8)"
            class="mx-auto"
            href="/examples/blend4web/flame_webgl.html"
            target="_blank"
            >
           <p>view 4D Webgl</p>
            <v-icon class="ml-auto">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-btn>
      </div>
      <div id="youtube">
        <div>
          <LazyYoutubeVideo src="https://www.youtube.com/embed/nMLfhwQfflE" />
        </div>
      </div>
    </slide>
    <!-- kubik -->
    <slide :index="3" id="back_slide">
      <div id="link">
            <v-btn
            elevation="2"
            block
            small
            dark
            color="rgba(255, 198, 7, 0.8)"
            class="mx-auto"
            href="/examples/verge3d/animaciya-kubika-rubika/animaciya-kubika-rubika.html"
            target="_blank"
            >
            <p>view 4D Webgl</p>
            <v-icon class="ml-auto">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-btn>
      </div>
      <div id="youtube">
        <div>
          <LazyYoutubeVideo src="https://www.youtube.com/embed/SN5Lbwjso5Q" />
        </div>
      </div>
    </slide>
        <slide :index="4" id="back_slide">
      <div id="link">
            <v-btn
            elevation="2"
            block
            small
            dark
            color="rgba(255, 198, 7, 0.8)"
            class="mx-auto"
            href="/examples/verge3d/landing_page_tele2/landing_page_tele2.html"
            target="_blank"
            >
            <p>view 4D Webgl</p>
            <v-icon class="ml-auto">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-btn>
      </div>
      <div id="youtube">
        <div>
          <LazyYoutubeVideo src="https://www.youtube.com/embed/13Q2713zLXI" />
        </div>
      </div>
    </slide>
  </carousel-3d>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import LazyYoutubeVideo from "vue-lazy-youtube-video";
  export default {
    name: 'AnimPhiz',
    components: {
      Carousel3d,
      Slide,
      LazyYoutubeVideo,
    },
    data: () => ({
      length: 3,
      onboarding: 0,
    }),

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
    },
  }
</script>
<style scoped>
#carousel {
  background-color: transparent;
}
#link {
    top: 0;
    left: 0;
    overflow: inherit;
    background: transparent; /* Цвет фона */
}
#youtube {
    top: 10px;
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
    background-color: transparent;

}
p {
  position: absolute;
  color: #265787;
  margin: 20px;
  opacity: 1;
  transition: opacity 0.6s;
 font-size: 15pt;
 font-family: "ToxicPowers";
}
router-link li a {
    text-decoration: none;
}
#back_slide {
  background-color: transparent;

}
@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
