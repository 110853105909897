<template>
  <div>
    <v-app-bar
    app
    color="rgba(216, 216, 216, 0.7)"
    flat
    dense
  >
    <template>      
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay          
        >
          <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                color="primary"
                v-bind="attrs"
              v-on="on"
                ></v-app-bar-nav-icon>
          </template>               
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>WebGL Map</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <template>
              <v-expansion-panels
               focusable 
               inset>
                <v-expansion-panel>
                  <v-expansion-panel-header>Home</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                    Quick access to the main pages of the site
                    <v-tab to="/" tag="button" id="tabb2"
                    @click="dialog = false">
                      blend3dweb.ru
                    </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Examples</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  Examples of webgl applications
                    <v-tab to="/examples" tag="button" id="tabb2"
                    @click="dialog = false">
                          examples
                    </v-tab>
                    <v-tab to="/verge3d" tag="button" id="tabb2"
                    @click="dialog = false">
                      Verge3D
                    </v-tab>
                    <v-tab to="/armory3d" tag="button" id="tabb2"
                    @click="dialog = false">
                      Armory3D
                    </v-tab>
                    <v-tab to="/blend4web" tag="button" id="tabb2"
                    @click="dialog = false">
                      Blend4Web
                    </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Contacts</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                    Developer contact
                  <v-tab to="/contacts" tag="button" id="tabb2"
                  @click="dialog = false">
                        contacts
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Webgl24 / Symbiosis Webgl</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  Experimenting with WebGL + Builds in 24 hours
                  <v-tab to="/webgl24" tag="button" id="tabb2"
                  @click="dialog = false">
                  Webgl24 = Symbiosis Webgl
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>AR / VR</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  fills up
                  <v-tab to="/" tag="button" id="tabb2"
                  @click="dialog = false">
                  link
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Verge3D</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  fills up
                  <v-tab to="/" tag="button" id="tabb2"
                  @click="dialog = false">
                  link
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Armory3D</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  fills up
                  <v-tab to="/" tag="button" id="tabb2"
                  @click="dialog = false">
                  link
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Blend4Web</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  fills up
                  <v-tab to="/" tag="button" id="tabb2"
                  @click="dialog = false">
                  link
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>PlayCanvas</v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="text-center">
                  fills up
                  <v-tab to="/" tag="button" id="tabb2"
                  @click="dialog = false">
                  link
                      </v-tab>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>    
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-tabs
      centered
      class="ml-auto"
      color="lime"
      dark
    >
    <v-tab to="/" tag="button" id="tabb">
      blend3dweb.ru
    </v-tab>
    <v-tab to="/examples" tag="button" id="tabb">
      examples
    </v-tab>
    <v-tab to="/contacts" tag="button" id="tabb">
      contacts
    </v-tab>
        <v-tab tag="button" id="tabb">
            <v-row justify="space-around">
              <v-col cols="auto">
                <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >price</v-btn>
                  </template>
                  <template>
                    <form action="send.php" method="post">
                            <v-card>
                              <v-toolbar
                                color="primary"
                                dark
                              >Стоимость Webgl - Webgl Price</v-toolbar>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-text-field
                                          label="Имя|Name*"
                                          required
                                          name="fio"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-text-field
                                          label="Почта|Email*"
                                          required
                                          name="mail"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-text-field
                                          label="Телефон|Telephone*"
                                          required
                                          name="tel"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="12"
                                      >
                                        <v-textarea
                                          auto-grow	
                                          full-width	
                                          background-color="amber lighten-4"
                                          solo
                                          name="message"
                                          label="Сообщение|Message*"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                  <small>*обязательное поле|required field</small>
                                </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                color="light-blue lighten-5"
                                type="submit"  
                                depressed                
                                >Отправить | Send</v-btn>
                              </v-card-actions>
                            </v-card>
                    </form>  
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
    </v-tab>
    </v-tabs>
    </v-app-bar>
  </div>


</template>
<script>
// @ is an alias to /src
export default {
  name: 'SpeedDial',
  data: () => ({
   // drawer: false,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
  }),
}
</script>
<style scoped>
#tabb {
	/* position: absolute;
	padding: 20px; */
	color: #265787;
  /* color: cyan;   */
	opacity: 1;
	transition: opacity 0.6s;
	/* left: 0px;
	right: 0px;
	bottom: 25%; */
	font-family: "ToxicPowers";
	/* text-align: center;
	font-size: 32px; */
}
#tabb2 {
/* 	position: absolute; */
/* 	padding: 20px; */
	color: #265787;
  /* color: cyan;   */
	opacity: 1;
	transition: opacity 0.6s;
/* 	left: 50%; */
/* 	right: 20px; */
/* 	bottom: 25%; */
	font-family: "ToxicPowers";
	/* text-align: center;
	font-size: 32px; */
  margin-left: auto;
  margin-right: auto;
  line-height: 2;
}

@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
@font-face {
font-family: "Stroke(RUS BY LYAJKA)";
src: url("/fonts/strokerusbylyajka_medium.otf");

}
.v-expansion-panel-header {
  font-family: "Stroke(RUS BY LYAJKA)";
  letter-spacing: 0.1em;
  font-size:20pt;  

}
.v-expansion-panel-content {
  font-family: "Stroke(RUS BY LYAJKA)";
  letter-spacing: 0.1em;
  font-size:15pt;  
 }
</style>
