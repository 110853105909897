import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
   // component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/examples',
    name: 'Examples',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "examples" */ '../views/Examples.vue')
  },
  {
    path: '/webgl24',
    name: 'Webgl24',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "webgl24" */ '../views/Webgl24.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/blend4web',
    name: 'Blend4web',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blend4web" */ '../components/examples_vue/Blend4Web.vue')
  },
  {
    path: '/armory3d',
    name: 'Armory3D',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "armory3d" */ '../components/examples_vue/Armory3D.vue')
  },
  {
    path: '/playcanvas',
    name: 'PlayCanvas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "playcanvas" */ '../components/examples_vue/PlayCanvas.vue')
  },
  {
    path: '/verge3d',
    name: 'Verge3D',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "verge3d" */ '../components/examples_vue/Verge3D.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
