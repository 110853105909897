<template>
  <v-container fluid class="mx-auto">
            <h1 class="text-center mb-5">Interactive Graphics 4D Webgl</h1>
            <h2 class="py-2">Blender</h2>
            <h3 class="py-2">Vue.js | React.js</h3>
            <h4 class="py-2">Blend4Web | Verge3D | Armory3D | Three.js</h4>
            <v-divider class="mx-16"></v-divider>
            <v-divider class="mx-16"></v-divider>
            <p></p>
            <v-container class="py-2">       
                <p>3D graphics are not always something that can be simply visualized into pictures, videos and viewed. In the browser, 3D content can be manipulated and fully interacted with.</p>
                <p>By combining technologies and capabilities of different programs, we managed to add interactivity to 3D.</p>
                <p>There may be other technologies in the future, but for now we do 4D with <a href="https://www.blender.org/">Blender</a> and WebGL.</p>
           </v-container>

             <h2 class="py-2">Directions and Development</h2>
            <v-divider class="mx-16"></v-divider>
            <h3 class="py-3 pt-5">Interactive Configurators</h3>
            <Configurators />

            <v-divider class="mx-16"></v-divider>


            <h3 class="py-3 pt-5">Architectural, interior and landscape rendering</h3>
            <ArchInterLand />

            <v-divider class="mx-16"></v-divider>
            <h3 class="py-3 pt-5">WebGL Animation and Physics</h3>
            <AnimPhiz />

            <v-divider class="mx-16"></v-divider>
            <h3 class="py-3 pt-5">WebGL rendering of Dialux lighting and fictitious colors</h3>
            <Lighting />

            <v-divider class="mx-16"></v-divider>

            <h3 class="py-3 pt-5">Infographic</h3>
            <Infographics />

            <v-divider class="mx-16"></v-divider>
            <h3 class="py-3 pt-5">Object Rendering</h3>
            <Items />
            <v-divider class="mx-16"></v-divider>
            
            <v-divider class="mx-16"></v-divider>
            <h3 class="py-3 pt-5">Games Webgl</h3>
            <Games />
            <v-divider class="mx-16"></v-divider>
  </v-container>
</template>

<script>
import Configurators from '../components/home_vue/Configurators.vue'
import ArchInterLand from '../components/home_vue/ArchInterLand.vue'
import AnimPhiz from '../components/home_vue/AnimPhiz.vue'
import Lighting from '../components/home_vue/Lighting.vue'
import Infographics from '../components/home_vue/Infographics.vue'
import Items from '../components/home_vue/Items.vue'
import Games from '../components/home_vue/Games.vue'
export default {
    name: 'Blend3DWeb',
    components: {     
    Configurators,
    ArchInterLand,
    AnimPhiz,
    Lighting,
    Infographics,
    Items,
    Games,
   
},
    data: () => ({
      
          }),
  }
</script>
<style scoped>
p {
  font-family: "Stroke(RUS BY LYAJKA)";
 font-size: 15pt;
 letter-spacing: 0.05em;
 }
 h1, h2, h3, h4 {
  font-family: "Stroke(RUS BY LYAJKA)";
  letter-spacing: 0.1em;
 }
 @font-face {
font-family: "Stroke(RUS BY LYAJKA)";
src: url("/fonts/strokerusbylyajka_medium.otf");

}
</style>
