<template>
  <div class="home">
	<Webgl24Bar/>
    <Blend3DWeb />
  </div>
</template>

<script>
// @ is an alias to /src.........
import Blend3DWeb from '@/components/Blend3DWeb.vue';
import Webgl24Bar from '@/components/controls_vue/Webgl24Bar.vue';
export default {
  name: 'Home',
  components: {
    Blend3DWeb,
    Webgl24Bar,
  }
}
</script>
<style scoped>
#tabb {
	/* position: bottom; */
	/* padding: 20px; */
	color: #265787;
  /* color: cyan;   */
	opacity: 1;
	transition: opacity 0.6s;
	/* left: 0px;
	right: 0px;
	bottom: 25%; */
	font-family: "ToxicPowers";
	/* text-align: center;
	font-size: 32px; */
}

@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
