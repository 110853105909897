<template>
  <v-app>
    <div> 
        
          <SpeedDial />
          <v-container fluid class="mx-auto mt-16 pt-10 text--secondary text-center">
            <transition name="page">
              <router-view/>
            </transition>           
          </v-container>       
    </div>
  </v-app>
</template>
<script>
// @ is an alias to /src
import SpeedDial from '@/components/controls_vue/SpeedDial.vue'
export default {
  name: 'App',
  components: {
    SpeedDial,
  }
}
</script>
<style scoped>
  .page-enter-active, .page-leave-active {
  transition: all 1s ease;
  opacity: 0;
  transform: translate(0, -50%);
}
</style>
