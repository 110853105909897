import { render, staticRenderFns } from "./Games.vue?vue&type=template&id=70846e36&scoped=true&"
import script from "./Games.vue?vue&type=script&lang=js&"
export * from "./Games.vue?vue&type=script&lang=js&"
import style0 from "./Games.vue?vue&type=style&index=0&id=70846e36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70846e36",
  null
  
)

export default component.exports